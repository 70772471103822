require('./bootstrap');

require('datatables.net-bs4');

require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-responsive-bs4');
require('../../vendor/yajra/laravel-datatables-buttons/src/resources/assets/buttons.server-side');
require('../../vendor/unisharp/laravel-filemanager/public/js/stand-alone-button');

require('jquery-form');

window.Swal = require('sweetalert2');

window.swal_options_default = {
    // confirmButtonColor: '#d33',
    // cancelButtonColor: '#d33',
    confirmButtonText: 'Continua'
    // confirmButtonClass: 'btn btn-danger',
    // cancelButtonClass: 'btn btn-light',
    // buttonsStyling: false,
};
window.swal_options_delete = {
    title: 'Attenzione!',
    text: "Stai per eliminare l'elemento selezionato, vuoi continuare?",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#ac4a59'
};
window.swal_options_error = {
    type: 'error', title: 'Si è verificato un errore', showConfirmButton: false, timer: 3500,
};
window.swal_options_success = {
    type: 'success', title: 'Operazione completata', showConfirmButton: false, timer: 3500,
}
$.extend(swal_options_delete, swal_options_default);
$.extend(swal_options_error, swal_options_default);

$("body").on("click", ".btn-delete", function (e) {

    e.preventDefault();

    var form_id = $(this).data("form_id");
    var obj = $(this);
    var form = obj.closest('form');
    var dt = form.closest('table.dataTable');

    Swal.fire(swal_options_delete).then(function (result) {
        if (result.value) {
            form.ajaxSubmit({
                beforeSubmit: function (formData, jqForm, options) {
                }, success: function (responseText, statusText, xhr, $form) {
                    if (responseText.success) {
                        $(dt).DataTable().ajax.reload();
                    } else {
                        Swal.fire(swal_options_error);
                    }
                }
            });
        }
    });
});

require('jquery-validation/dist/localization/messages_it');
$.validator.setDefaults({
    lang: 'it', highlight: function (element) {
        if ($(element).data('select2-id')) {
            $(element).next("span.select2").children('span.selection').children('span.select2-selection')
                .addClass('is-invalid')
                .removeClass('is-valid');
        } else {
            $(element).addClass("is-invalid").removeClass("is-valid");
        }
    }, unhighlight: function (element) {
        var elm = $(element);
        if (elm.data('select2-id')) {
            elm.next("span.select2").children('span.selection').children('span.select2-selection')
                .addClass('is-valid')
                .removeClass('is-invalid')
        } else if (elm.prop("type") === "radio") {
            $('input[name="' + elm.attr('name') + '"]').each(function (i, e) {
                $(e).removeClass("is-invalid");
            });
        } else {
            elm.addClass("is-valid").removeClass("is-invalid");
        }
    }, errorElement: 'em', // errorClass: 'help-block',
    errorPlacement: function (error, element) {
        error.addClass("invalid-feedback");
        if (element.prop("type") === "checkbox") {
            error.appendTo(element.closest(".check-group"));
        } else if (element.data('select2-id')) {
            error.insertAfter(element.next("span.select2"));
        } else if (element.prop("type") === "radio") {
            $('input[name="' + element.attr('name') + '"]').each(function (i, e) {
                $(e).addClass("is-invalid");
            });
            error.appendTo(element.closest(".radio-group"));
        } else {
            error.insertAfter(element);
        }
    }, ignore: ".summernote"
});

import moment from 'moment';
import bsCustomFileInput from 'bs-custom-file-input';

window.bsCustomFileInput = bsCustomFileInput;

require('daterangepicker');

require('select2');
require('select2/dist/js/i18n/it');

window.select2_option_default = {
    theme: 'bootstrap4', lang: 'it', allowClear: true, placeholder: "Seleziona", width: '100%',
};

window.daterangepicker_opt = {
    locale: {
        format: 'DD/MM/YYYY',
        applyLabel: "Applica",
        cancelLabel: 'Cancella',
        startLabel: 'Data inizio',
        endLabel: 'Data Fine',
        customRangeLabel: 'Seleziona una data', // daysOfWeek: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi','Samedi'],
        daysOfWeek: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
        monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
    },
    autoUpdateInput: false,
    alwaysShowCalendars: true,
    minDate: "",
    showDropdowns: true,
    firstDay: 1,
    startDate: moment(),
    buttonClasses: "btn",
    applyClass: "btn-info",
    cancelClass: "btn-secondary",
    ranges: {
        'Oggi': [moment(), moment()],
        'Ieri': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Ultima 7 giorni': [moment().subtract(6, 'days'), moment()],
        'Ultimi 30 giorni': [moment().subtract(29, 'days'), moment()],
        'Questo mese': [moment().startOf('month'), moment().endOf('month')],
        'Scorso mese': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
};

window.toastr = require('toastr');
window.Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});


window.notify = function notify(tipo, messaggio) {
    Toast.fire({
        icon: tipo,
        title: messaggio
    })
}

$(document).ready(function (e) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $('.select2').select2(select2_option_default);
    var select2_option_tags = {};
    $.extend(select2_option_tags, select2_option_default);
    select2_option_tags.tags = true;
    $('.tags').select2(select2_option_tags);

    $('.daterange').daterangepicker(daterangepicker_opt);
    $('.daterange').on('apply.daterangepicker', function (ev, picker) {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        picker.element.trigger('change');
    });
    $('.daterange').on('cancel.daterangepicker', function (ev, picker) {
        picker.element.val('');
        picker.element.trigger('change');
    });

    $("form.default-validate").validate();
    bsCustomFileInput.init();

    // Inputmask().mask(document.querySelectorAll("input"));
});

